import { useEffect, useState } from "react";
import { Route, Routes, useLocation } from "react-router-dom";
import { ThemeProvider } from "@mui/material";
import Home from "./pages/Home";
import About from "./pages/About";
import Contact from "./pages/Contact";
import Recipes from "./pages/Recipes";
import Shop from "./pages/Shop";
import Navigation from "./components/Navigation";
import Footer from "./components/Footer";
import Product from "./pages/Product";
import { theme } from "./theme";

const AppRouter = () => {
  const { pathname } = useLocation();
  const [basketDrawerOpen, setBasketDrawerOpen] = useState(false);

  useEffect(() => {
    window.scrollTo(0, 0);
  }, [pathname]);

  return (
    <ThemeProvider theme={theme}>
      <Navigation basketDrawerOpen={basketDrawerOpen} setBasketDrawerOpen={setBasketDrawerOpen} />
      <Routes>
        <Route path="/" element={<Home />} />
        <Route path="/about" element={<About />} />
        <Route path="/contact" element={<Contact />} />
        <Route path="/recipes" element={<Recipes />} />
        <Route path="/shop/:handle" element={<Shop />} />
        <Route path="/products/:id" element={<Product setBasketDrawerOpen={setBasketDrawerOpen} />} />
      </Routes>
      <Footer />
    </ThemeProvider>
  );
};

export default AppRouter;
