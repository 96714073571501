import React from "react";
import { Typography } from "@mui/material";
import cow6 from "../assets/cows_6.jpg";
import cow4 from "../assets/cows_4.jpg";
import { Section, SectionItem } from "../styles/Layout";

const Contact: React.FC = () => {
  return (
    <>
      <Section direction="row">
        <SectionItem>
          <Typography variant="h3" paddingY={{ xs: 2, md: 4 }}>
            Say Hello
          </Typography>
        </SectionItem>
      </Section>

      <Section direction="row">
        <SectionItem flexBasis={{ xs: "100%", md: "50%" }} border>
          <img src={cow6} alt="cows" />
        </SectionItem>

        <SectionItem justifyContent="center" padding={{ xs: 4, md: 8 }} flexBasis={{ xs: "100%", md: "50%" }}>
          <Typography variant="h3">Alasdair</Typography>
          <Typography letterSpacing={0.34} marginY={2}>
            <a href="mailto:alasdair@luckingtonfarm.com">alasdair@luckingtonfarm.com</a>
          </Typography>
          <Typography letterSpacing={0.34}>07881388292</Typography>
        </SectionItem>
      </Section>

      <Section direction={{ xs: "column", md: "row-reverse" }}>
        <SectionItem flexBasis={{ xs: "100%", md: "50%" }}>
          <img src={cow4} alt="cows" />
        </SectionItem>

        <SectionItem justifyContent="center" padding={{ xs: 4, md: 8 }} flexBasis={{ xs: "100%", md: "50%" }} border>
          <Typography variant="h3">Janie</Typography>
          <Typography letterSpacing={0.34} marginY={2}>
            <a href="mailto:janie@luckingtonfarm.com">janie@luckingtonfarm.com</a>
          </Typography>
          <Typography letterSpacing={0.34}>07843273433</Typography>
        </SectionItem>
      </Section>
    </>
  );
};

export default Contact;
