import { Typography } from "@mui/material";
import cow3 from "../assets/cows_3.jpg";
import cow4 from "../assets/cows_4.jpg";
import cow5 from "../assets/cows_5.jpg";
import cow6 from "../assets/cows_6.jpg";
import beef from "../assets/beef.jpg";
import { FirstSection, Section, SectionItem } from "../styles/Layout";

const About: React.FC = () => {
  return (
    <div className="about-page">
      <FirstSection>
        <img className="top" src={cow3} alt="cows" />
      </FirstSection>

      <Section direction={{ xs: "column", md: "row" }}>
        <SectionItem gap={2} flexBasis={{ xs: "100%", md: "50%" }} border padding={{ xs: 4, md: 8 }}>
          <Typography variant="h3">The Environment</Typography>
          <Typography>
            Organic farming and "mob grazing" mean the grasses and pasture have a short intense period of being eaten and then
            long periods of just doing their thing: growing. Because the grasses are allowed to grow to maturity there is an
            exponential effect on the amount of carbon sequestered from the atmosphere back into the ground. The mid-range growth
            of grasses (say from I 0cm to 30cm) is by far the fastest growth period and hence the most efficient sequester of
            carbon, far more so than trees or shrubs. (To appreciate this imagine the volume of grass that would grow on your lawn
            over a few weeks in summer if left to its own devices, versus the growth in a tree or bush over the same period).
            Grasses that are grazed with no restriction (set-stocking) are not able to do this to such an extent. In addition,
            longer grasses and a variety of species, promotes a rich habitat for insects above and below ground which in turn
            support a much more vibrant ecosystem of bugs, butterflies, birds etc.
          </Typography>
        </SectionItem>
        <SectionItem flexBasis={{ xs: "100%", md: "50%" }}>
          <img src={cow4} alt="cows" />
        </SectionItem>
      </Section>

      <Section direction={{ xs: "column", md: "row-reverse" }}>
        <SectionItem gap={2} flexBasis={{ xs: "100%", md: "50%" }} padding={{ xs: 4, md: 8 }}>
          <Typography variant="h3">The Land</Typography>
          <Typography>
            "Mob grazing" cattle means keeping the herd closely bunched together, (mimicking how they would graze in the wild when
            they need to protect themselves from predators) and moving them very often, normally every day. This means that the
            pasture has a short, intense period of being eaten, pooed on and trampled; which builds soil and soil carbon. The
            pasture is then left alone for many weeks allowing it to grow, flower and seed. This encourages a more diverse array
            of pasture - grasses, legumes, wild flowers and herbs; which in turn means more varied root structures below ground as
            well. The above ground pasture volume reduces rain water evaporation thus preserving moisture and the more robust root
            system aids water retention and reduces water run off, thus preventing soil degradation. We aim to create a nutrient
            rich environment above and below ground for wildlife, insects, earthworms, mycorrhizal fungi; all of which is vital
            for soil health.
          </Typography>
        </SectionItem>
        <SectionItem flexBasis={{ xs: "100%", md: "50%" }} border>
          <img src={cow5} alt="cows" />
        </SectionItem>
      </Section>

      <Section direction={{ xs: "column", md: "row" }}>
        <SectionItem gap={2} border flexBasis={{ xs: "100%", md: "50%" }} padding={{ xs: 4, md: 8 }}>
          <Typography variant="h3">The Animal</Typography>
          <Typography>
            The cattle are moved every day and are thus presented with fresh grazing daily. They are given a measured area, the
            aim being that they will take approximately the top third of the plants, leaving two thirds behind which is pooed on
            and trampled.They eat a fresh variety of grasses, herbs, legumes, flowers etc which this system allows and is
            healthier and more nutritious for them than a 'set-stocked' (put in a field for a long period) system when diversity
            tends to give way to monoculture. Diversity also allows the cattle to self-select plants some of which have medicinal
            properties; the cattle know what is good for them. We aim to avoid them eating the bottom third of grasses where the
            worms hang out, thus reducing the need for worming and having a positive impact on the soil as warmers in cattle get
            pooed out and end up killing soil life as collateral damage. We aim to have the cattle outside as much of the year as
            possible and if they do have to be inside they a1·e only fed preserved pasture for-age (silage, haylage or hay).
          </Typography>
        </SectionItem>
        <SectionItem flexBasis={{ xs: "100%", md: "50%" }}>
          <img src={cow6} alt="cows" />
        </SectionItem>
      </Section>

      <Section direction={{ xs: "column", md: "row-reverse" }}>
        <SectionItem gap={2} flexBasis={{ xs: "100%", md: "50%" }} padding={{ xs: 4, md: 8 }}>
          <Typography variant="h3">The Taste</Typography>
          <Typography>
            What is "grass fed"? Surely all cattle are grass fed. Well. most cattle live in a field and eat grass when it is
            available but are then "finished" (ie fattened up quickly) on grain (or cereals/ soya). Grain is simply a way of
            getting cattle to put on weight; it is not a natural diet for ruminants. (Incidently the production of grain itself is
            often damaging for the environment). Being soley grass fed on a diverse mix of grasses, herbs, flowers and legumes
            means the cattle fatten slowly and naturally; the meat tends to be lower in total fat, higher on Omega 3 fatty acids,
            higher in linoleic acid and higher in vitamin and mineral contents than grain fed beef. All of which means it is a lot
            better for you. But what is more it tastes better... please try it for yourself and let us know what you think.
          </Typography>
        </SectionItem>
        <SectionItem flexBasis={{ xs: "100%", md: "50%" }} border>
          <img src={beef} alt="taste" />
        </SectionItem>
      </Section>
    </div>
  );
};

export default About;
