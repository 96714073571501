import React from "react";
import styled from "styled-components";
import { Typography } from "@mui/material";
import { colors } from "../theme";

const StyledFooter = styled.footer`
  background-color: ${colors.dark};
  width: 100%;
  display: flex;
  align-items: center;
  justify-content: center;
`;

const ContactDetailsContainer = styled.div`
  margin: 80px;
  width: 67%;
  border: 2px solid ${colors.dark};
  background: ${colors.light};
  z-index: 2;

  @media screen and (max-width: 895px) {
    width: 100%;
    margin: 15px;
  }
`;

const ContactDetails = styled.div`
  display: flex;
  justify-content: space-around;
  text-align: center;
  margin: 8px;
  padding: 50px 15px;
  border: 2px solid ${colors.dark};

  @media screen and (max-width: 895px) {
    flex-wrap: wrap;
    padding: 15px;
  }
`;

const ContactItem = styled.div`
  flex-basis: 100%;
  padding: 0 24px;

  &:not(:last-child) {
    border-right: 2px dotted ${colors.dark};
    @media screen and (max-width: 895px) {
      border-right: 0;
      border-bottom: 2px dotted ${colors.dark};
    }
  }

  @media screen and (max-width: 895px) {
    padding: 24px;
  }
`;

const Footer: React.FC = () => {
  return (
    <StyledFooter>
      <ContactDetailsContainer>
        <ContactDetails>
          <ContactItem>
            <Typography variant="subtitle2" textTransform="uppercase" letterSpacing={1.65} marginBottom={2}>
              Address
            </Typography>

            <Typography>Fosse Tillery, Brokenborough, Malmesbury, Wiltshire, SN16 0JL</Typography>
          </ContactItem>
          <ContactItem>
            <Typography variant="subtitle2" textTransform="uppercase" letterSpacing={1.65} marginBottom={2}>
              Follow us
            </Typography>
            <div>
              <a href="https://www.instagram.com/luckingtonfarm/" target="_blank" rel="noreferrer">
                <Typography>@luckingtonfarm</Typography>
              </a>
            </div>
          </ContactItem>
          <ContactItem>
            <Typography variant="subtitle2" textTransform="uppercase" letterSpacing={1.65} marginBottom={2}>
              Contact us
            </Typography>
            <div>
              <Typography>
                <a href="mailto:alasdair@luckingtonfarm.com">alasdair@luckingtonfarm.com</a>
              </Typography>
              <Typography>07881388292</Typography>
            </div>
          </ContactItem>
        </ContactDetails>
      </ContactDetailsContainer>
    </StyledFooter>
  );
};

export default Footer;
