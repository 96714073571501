import { useState, useMemo } from "react";
import { Link } from "react-router-dom";
import { Menu, ShoppingBagOutlined } from "@mui/icons-material";
import { Badge, Typography, useMediaQuery, useTheme } from "@mui/material";
import logoSvg from "../../assets/logo.svg";
import {
  MobileNavItem,
  NavContainer,
  NavItem,
  NavItemContainer,
  NavList,
  StyledLogo,
  LuckingtonLogoMobile,
  BasketNavItem,
} from "./styles";
import { useGetBasketQuery } from "../../generated/graphql";
import BasketDrawer from "./BasketDrawer";
import MobileNavDrawer from "./MobileNavDrawer";
import NavItemDropdown from "./NavItemDropdown";

type Props = {
  basketDrawerOpen: boolean;
  setBasketDrawerOpen: (open: boolean) => void;
};

const Navigation = ({ basketDrawerOpen, setBasketDrawerOpen }: Props) => {
  const theme = useTheme();
  const isMobile = useMediaQuery(theme.breakpoints.down("md"));
  const [mobileNavOpen, setMobileNavOpen] = useState(false);
  const basketId = localStorage.getItem("basketId") || "";
  const { data } = useGetBasketQuery({ variables: { basketId } });

  const basketQuantity = useMemo(() => {
    if (!data?.cart?.lines?.nodes) return 0;
    return data.cart.lines.nodes.reduce((acc, curr) => acc + curr.quantity, 0);
  }, [data]);

  return (
    <NavContainer>
      <MobileNavDrawer open={mobileNavOpen} onClose={() => setMobileNavOpen(false)} />
      {isMobile && (
        <MobileNavItem onClick={() => setMobileNavOpen(true)} position="left">
          <Menu color="primary" fontSize="large" />
        </MobileNavItem>
      )}
      {isMobile && (
        <LuckingtonLogoMobile>
          <Link to="/">
            <StyledLogo src={logoSvg} alt="luckington farm" />
          </Link>
        </LuckingtonLogoMobile>
      )}
      {!isMobile && (
        <NavList>
          <NavItem to="/">
            <NavItemContainer>
              <Typography>HOME</Typography>
            </NavItemContainer>
          </NavItem>

          <NavItemDropdown
            title="SHOP"
            items={[
              { to: "/shop/poultry", title: "POULTRY" },
              { to: "/shop/beef", title: "BEEF" },
              { to: "/shop/lamb", title: "LAMB" },
            ]}
          />

          <NavItem to="/about">
            <NavItemContainer>
              <Typography>ABOUT</Typography>
            </NavItemContainer>
          </NavItem>

          <NavItem to="/">
            <StyledLogo src={logoSvg} alt="luckington farm" />
          </NavItem>

          <NavItem to="/recipes">
            <NavItemContainer>
              <Typography>RECIPES</Typography>
            </NavItemContainer>
          </NavItem>
          <NavItem to="/contact">
            <NavItemContainer>
              <Typography>CONTACT</Typography>
            </NavItemContainer>
          </NavItem>

          <BasketNavItem onClick={() => setBasketDrawerOpen(!basketDrawerOpen)}>
            <NavItemContainer>
              <Typography>BASKET ({basketQuantity})</Typography>
            </NavItemContainer>
          </BasketNavItem>
        </NavList>
      )}
      {isMobile && (
        <MobileNavItem onClick={() => setBasketDrawerOpen(true)} position="right">
          <Badge badgeContent={basketQuantity} color="primary">
            <ShoppingBagOutlined color="primary" fontSize="large" />
          </Badge>
        </MobileNavItem>
      )}
      <BasketDrawer onClose={() => setBasketDrawerOpen(false)} open={basketDrawerOpen} />
    </NavContainer>
  );
};

export default Navigation;
