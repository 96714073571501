import React from "react";
import { Typography } from "@mui/material";
import recipeMeat from "../assets/RecipeMeat.jpg";
import dish from "../assets/dish.jpg";
import beefSmore from "../assets/BeefSmore.jpg";
import { FirstSection, Section, SectionItem } from "../styles/Layout";

const Recipes: React.FC = () => {
  return (
    <div>
      <FirstSection>
        <img src={recipeMeat} alt="recipe meat" />
      </FirstSection>
      <Section direction="row">
        <SectionItem padding={{ xs: 4, md: 8 }} border flexBasis={{ xs: "100%", md: "50%" }}>
          <Typography variant="h3">
            We love experimenting with our beef and below is one of our favourite recipes. If you’re feeling inspired in the
            kitchen, please send us your creations to share in our section.
          </Typography>
        </SectionItem>
        <SectionItem flexBasis={{ xs: "100%", md: "50%" }} maxHeight={{ xs: "40vh", md: "70vh" }}>
          <img src={dish} alt="dish" />
        </SectionItem>
      </Section>
      <Section direction="row">
        <SectionItem paddingY={{ xs: 2, md: 4 }}>
          <Typography variant="h3">Sri Lankan Beef Smore</Typography>
        </SectionItem>
      </Section>
      <Section direction="row">
        <SectionItem>
          <img src={beefSmore} alt="beef smore" style={{ maxHeight: "600px" }} />
        </SectionItem>
      </Section>
      <Section direction="row">
        <SectionItem border flexBasis={{ xs: "100%", md: "50%" }}>
          <Section padding={4} paddingBottom={{ xs: 0, md: 4 }} alignItems="center">
            <Typography variant="h3">Ingredients</Typography>
          </Section>
          <Section style={{ height: "100%" }} padding={{ xs: 4, md: 6 }}>
            <ul>
              <li>
                <Typography>Braising beef - chuck, blade or brisket</Typography>
              </li>
              <li>
                <Typography>Salt and pepper</Typography>
              </li>
              <li>
                <Typography>4 teaspoons coriander seeds</Typography>
              </li>
              <li>
                <Typography>1 teaspoon cumin seeds</Typography>
              </li>
              <li>
                <Typography>1 teaspoon fennel seeds</Typography>
              </li>
              <li>
                <Typography>1/4 teaspoon fenugreek seeds - we used ground as we didn't have the seeds</Typography>
              </li>
              <li>
                <Typography>4 tablespoons oil</Typography>
              </li>
              <li>
                <Typography>5 cm stick of cinnamon</Typography>
              </li>
              <li>
                <Typography>1 large onion - chopped</Typography>
              </li>
              <li>
                <Typography>5 cm piece of ginger - grated</Typography>
              </li>
              <li>
                <Typography>4 cloves garlic - chopped</Typography>
              </li>
              <li>
                <Typography>2 tablespoons red wine vinegar</Typography>
              </li>
              <li>
                <Typography>350ml stock - beef or chicken</Typography>
              </li>
              <li>
                <Typography>1 teaspoon cayenne</Typography>
              </li>
              <li>
                <Typography>250ml coconut milk</Typography>
              </li>
            </ul>
          </Section>
        </SectionItem>

        <SectionItem flexBasis={{ xs: "100%", md: "50%" }}>
          <Section padding={4} paddingBottom={{ xs: 0, md: 4 }} alignItems="center">
            <Typography variant="h3">Method</Typography>
          </Section>
          <Section direction="row" paddingX={4} paddingY={2}>
            <Typography>
              Pat the meat dry with kitchen paper, then sprinkle lightly with salt and lots of black pepper.
            </Typography>
          </Section>
          <Section direction="row" paddingX={4} paddingY={2}>
            <Typography>
              Put a small heavy-based frying pan over a medium heat. When very hot, put the coriander, cumin, fennel and fenugreek
              seeds. Stir for 30 seconds or so until the spices just start to emit a roasted aroma.
            </Typography>
          </Section>
          <Section direction="row" paddingX={4} paddingY={2}>
            <Typography>
              Empty on to a piece of kitchen paper and allow to cool a bit. Grind in a clean coffee or spice grinder, or crush in
              a mortar.
            </Typography>
          </Section>
          <Section direction="row" paddingX={4} paddingY={2}>
            <Typography>
              Preheat the oven to 160℃/gas mark 3. Put the oil in a flameproof casserole ad set over a medium-high heat. When hot,
              put in the meat and brown on all side. Transfer to a plate.
            </Typography>
          </Section>
          <Section direction="row" paddingX={4} paddingY={2}>
            <Typography>
              Return the casserole to the heat and add the cinnamon, onion, ginger and garlic. Stir and cook for 4-5 minutes. Add
              the vinegar, stock, cayenne pepper, 1 1/2 teaspoons salt, the roasted, ground spices and the beef with its
              accumulated juices. Bring to the boil, stirring the sauce.
            </Typography>
          </Section>
          <Section direction="row" paddingX={4} paddingY={2}>
            <Typography>
              Cover and place in oven. Cook, basting and turning every 20 minutes or so, for 2-2 1/2 hours, or until the meat is
              tender.
            </Typography>
          </Section>
          <Section direction="row" paddingX={4} paddingY={2}>
            <Typography>
              Transfer the casserole to the hob. Add the coconut milk, stir and bring to a simmer before serving.
            </Typography>
          </Section>
        </SectionItem>
      </Section>
    </div>
  );
};

export default Recipes;
