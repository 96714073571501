import { useState } from "react";
import styled from "styled-components";
import { Stack, Typography } from "@mui/material";
import { colors } from "../../theme";
import { DropdownNavItem, NavItemContainer } from "./styles";

const Container = styled.div`
  position: relative;
  flex: 1 1 0;
  height: 100%;
  text-decoration: none;
  color: inherit;
  border-right: 2px solid ${colors.dark};

  &.active > div {
    border-color: ${colors.dark};
  }

  @media screen and (max-width: 895px) {
    height: auto;
    flex: 0;
    border: 0;
    border-right: 0;
  }
`;

const Dropdown = styled(Stack)`
  position: absolute;
  bottom: 0;
  transform: translateY(calc(100% + 2px));
  left: -2px;
  width: calc(100% + 4px);
  background: ${colors.light};
  z-index: 3;
`;

const NavItemDropdown = ({ title, items }: { title: string; items: { to: string; title: string }[] }) => {
  const [open, setOpen] = useState(false);

  return (
    <Container>
      <NavItemContainer onClick={() => setOpen(!open)}>
        <Typography>{title}</Typography>
      </NavItemContainer>
      {open && (
        <Dropdown>
          {items.map((item) => (
            <DropdownNavItem to={item.to} onClick={() => setOpen(false)}>
              <NavItemContainer>
                <Typography>{item.title}</Typography>
              </NavItemContainer>
            </DropdownNavItem>
          ))}
        </Dropdown>
      )}
    </Container>
  );
};

export default NavItemDropdown;
