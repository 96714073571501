import { useState } from "react";
import styled from "styled-components";
import { Stack, Typography } from "@mui/material";
import { DropdownNavItem, NavItemContainer } from "./styles";

const Container = styled.div`
  position: relative;
`;

const Dropdown = styled(Stack)<{ open: boolean }>`
  width: 100%;
  max-height: 0;
  transition: max-height 0.3s linear;
  overflow: hidden;

  ${({ open }) => open && `max-height: 500px`}
`;

const DropdownNavItemContainer = styled.div`
  margin: 5px;
  text-align: center;
  height: 78px;
  display: flex;
  align-items: center;
  justify-content: center;
  height: 55px;
  border: 0;
`;

const MobileNavItemDropdown = ({
  title,
  items,
  onClose,
}: {
  title: string;
  items: { to: string; title: string }[];
  onClose: () => void;
}) => {
  const [open, setOpen] = useState(false);

  return (
    <Container>
      <NavItemContainer onClick={() => setOpen(!open)}>
        <Typography>{title}</Typography>
      </NavItemContainer>
      <Dropdown open={open}>
        {items.map((item) => (
          <DropdownNavItem to={item.to} onClick={onClose}>
            <DropdownNavItemContainer>
              <Typography fontSize={14} fontWeight={600}>
                {item.title}
              </Typography>
            </DropdownNavItemContainer>
          </DropdownNavItem>
        ))}
      </Dropdown>
    </Container>
  );
};

export default MobileNavItemDropdown;
